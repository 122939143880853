<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'admin-students'}"
        >
          Students List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <div class="d-flex justify-content-end align-items-center mb-2">
        <login-as-user
          v-if="canLoginAsUser"
          :user-id="userData.id"
          :user-role="userData.role.name"
        />
      </div>

      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>

        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-card>
        <user-view-user-info
          :user-data="userData"
          :validation-errors="studentUpdateValidationErrors"
          @updStudentInfo="updateStudentInfo"
        />
      </b-card>

      <applications-list
        :user-data="userData"
      />

      <task-list
        :user-data="userData"
      />

      <transaction-list
        v-if="$can('read', permissionSubjects.Transaction)"
        :user-data="userData"
      />

      <user-parents-list
        :user-data="userData"
      />

    </template>

  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import userStoreModule from '@/views/admin/students/studentsStoreModule'

import UserViewUserInfoCard from '@/views/admin/students/student-profile/StudentViewUserInfoCard.vue'
import UserViewUserPlanCard from '@/views/admin/students/student-profile/StudentViewUserPlanCard.vue'
import UserViewUserInfo from '@/views/admin/students/student-profile/StudentViewUserInfo.vue'

import ApplicationsList from '@/views/admin/students/student-applications/ApplicationsList.vue'
import TransactionList from '@/views/admin/students/student-transactions/TransactionsList.vue'
import TaskList from '@/views/admin/students/student-tasks/TaskList.vue'
import UserParentsList from '@/views/components/parents-list/UserParentsList.vue'
import useStudentProfile from '@/views/admin/students/student-profile/useStudentProfile'
import { permissionSubjects } from '@/libs/acl/constants'
import LoginAsUser from '@/views/components/login-as-user/LoginAsUser.vue'

export default {
  components: {
    LoginAsUser,
    BRow,
    BCol,
    BCard,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfo,
    UserViewUserInfoCard,
    UserViewUserPlanCard,

    ApplicationsList,
    TransactionList,
    TaskList,
    UserParentsList,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-student'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      updateStudent,
    } = useStudentProfile()

    store.dispatch('app-student/fetchStudent', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
      updateStudent,
    }
  },
  data() {
    return {
      studentUpdateValidationErrors: [],
      permissionSubjects,
    }
  },
  computed: {
    canLoginAsUser() {
      return this.$can('read', this.permissionSubjects.LoginAsParent)
    },
  },
  methods: {
    updateStudentInfo() {
      this.studentUpdateValidationErrors = []
      this.updateStudent(this.userData)
        .then(response => {
          if (response.status === 422) {
            const validationErrors = Object.values(response.data.errors)
            this.studentUpdateValidationErrors = validationErrors.flat()
          }
        })
    },
  },
}
</script>

<style>

</style>
