<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>
        <b-col
          sm="12"
          class="d-flex align-items-center mb-2"
        >
          <h4 class="mb-0 font-weight-normal">
            Applications
          </h4>
        </b-col>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            :reduce="option => option.value"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchApplications"
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <!-- Column: program -->
      <template #cell(program)="data">
        <b-link
          :to="{ name: 'admin-application-edit', params: { id: data.item.id, type: camperStudent(1) } }"
        >
          {{ data.value }}
        </b-link>
      </template>

      <!-- Column: members -->
      <template #cell(child)="data">
        <span>{{ data.item.user.full_name }}</span>
      </template>

      <!-- Column: status -->
      <template #cell(status)="data">
        <b-badge
          :variant="`light-${resolveStatusVariant(data.value)}`"
        >
          {{ data.value.name }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <!-- Dropdown -->
        <b-dropdown
          variant="link"
          toggle-class="mx-auto p-0"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-for="option in actionOptions"
            :key="option.value"
            :to="{ name: option.link, params: { id: data.item.id, type: camperStudent(1) } }"
          >
            <span>{{ option.title }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-if="totalItems && perPage !== 'all'"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BTable,
  BBadge, BDropdownItem, BDropdown, BPagination, BLink,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useApplicationsList from './useApplicationsList'

import storeModule from '../studentsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,

    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-student'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchApplications,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      resolveStatusVariant,
      actionOptions,

      refetchData,
    } = useApplicationsList(props.userData.id)

    return {
      fetchApplications,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      refetchData,

      resolveStatusVariant,
      actionOptions,

      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
