import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUserParentsList() {
  // Use toast
  const toast = useToast()

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'name', sortable: false },
    { key: 'email', label: 'email', sortable: false },
  ]

  const fetchParents = ID => axios
    .get('/auth/parents', {
      params: {
        family_id: ID ?? 'empty',
      },
    })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Parents',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const linkParent = queryParams => axios
    .post(`/auth/users/${queryParams.user_id}/invite-to-family`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'The invitation was successfully send',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error creating Fee',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchParents,
    linkParent,
    tableColumns,
  }
}
