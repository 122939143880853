<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <template #header>
        <h4
          class="d-flex align-items-center mb-0"
        >
          Parents
        </h4>

        <b-button
          v-b-modal.link-parent-modal
          class="ml-1"
          variant="primary"
          :disabled="!$can('update', permissionSubjects.Child) || !$can('update', permissionSubjects.Parent)"
        >
          <span
            class="ml-25 align-middle"
          >Invite Parent By Email</span>
        </b-button>
      </template>

      <b-table
        :items="items"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Column: name -->
        <template #cell(name)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="data.item.avatar"
          />
          <b-link
            :to="{ name: 'admin-parent-profile', params: { id: data.item.id } }"
          >{{ data.item.full_name }}</b-link>
        </template>

        <!-- Column: email -->
        <template #cell(email)="data">
          {{ data.item.email }}
        </template>
      </b-table>
    </b-card>

    <link-parent-modal
      :user-id="userData.id"
    />
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BLink,
  BAvatar,
  BButton,
} from 'bootstrap-vue'
import LinkParentModal from '@/views/components/parents-list/LinkParentModal.vue'
import useUserParentsList from '@/views/components/parents-list/useUserParentsList'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BAvatar,
    BButton,
    LinkParentModal,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      permissionSubjects,
    }
  },
  setup() {
    const {
      fetchParents,
      tableColumns,
    } = useUserParentsList()

    return {
      fetchParents,
      tableColumns,
    }
  },
  async created() {
    await this.fetchParents(this.userData.family_id)
      .then(response => {
        this.items = response
      })
  },
}
</script>

<style lang="scss">

</style>
